import React, { useEffect, useState } from "react";
import { Resizable } from "re-resizable";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Menu,
  Tooltip,
  InputBase,
  Grid,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  TableContainer,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { changeShiftStatus, getBookingInvoice } from "../../store/action";
import Notification from "../../components/Notification/Notification";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import moment from "moment";

import {
  getTempTimesheetById,
  deleteTimesheet,
} from "../../store/action/timesheet/timesheetAction";
import {
  getBookingTrust,
  getHospitalList,
  getWardByHospital,
  createFilter,
  deleteFilter,
  getFilterData,
  getBookingSpeciality,
} from "../../store/action/booking/bookingAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import axios from "axios";
import apiConfigs from "../../config/config";
import AlertDialog from "../../components/Alert/AlertDialog";
import {
  notificationFail,
  notificationSuccess,
} from "../../store/action/notificationMsg";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AddExpense from "./AddExpense";
import { customEnLocale } from "../../helper/customLocale";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "800px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 150,
    },
  },
  formControl: {
    width: "24%",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,

    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: 140,
      cursor: "pointer",
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
  gridWrapper: {
    border: "4px solid #eaecfb",
    overflowX: "scroll",
    // marginLeft:"30px",
    display: "flex",
    flexDirection: "column",
  },
  buttonWrapper: {
    border: "2px solid grey",
    display: "grid",
    borderRadius: "4px",
    marginRight: "5px",
  },
  linkWrapper: {
    display: "grid",
    marginRight: "5px",
  },
  buttonAlign: {
    marginRight: "10px",
    display: "flex",
  },
  customBackground: {
    backgroundColor: "white",
  },
  paperBackground: {
    backgroundColor: "#eaecfb",
  },
  resizableBox: {
    position: "relative",
    border: "1px solid #ccc",
    marginRight: "10px",
  },
  gridContent: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    boxSizing: "borderBox",
  },
}));

const InvoiceStudio = ({ match }) => {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const [speciality, setSpeciality] = useState([]);
  const [page, setPage] = React.useState(1);
  let firstDayOfWeek = getMonday(new Date());
  firstDayOfWeek = moment(firstDayOfWeek).format("MM/DD/yyyy");

  let lastDayOfWeek = getSunday(new Date());
  lastDayOfWeek = moment(lastDayOfWeek).format("MM/DD/yyyy");

  const [searchData, setSearchData] = useState({
    search: "",
    specialty: "",
    customer: "",
    site: "",
    ward: "",
    consultants: "",
    candidate: "",
    start_date: firstDayOfWeek,
    end_date: lastDayOfWeek,
    provisional_status: "CONFIRMED",
    module_name: "invoice-studio",
  });
  const [bookingNotify, setBookingNotify] = useState(false);

  const [loadings, setLoadings] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [timesheetMsgError, setTimesheetError] = useState("");

  const [tempTimesheets, setTempTimesheets] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [bookingRef, setBookingRef] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);
  const [bookingCandidate, setBookingCandidate] = useState([]);
  const [firstId, setFirstId] = useState(0);
  const [tempSheetId, setTempSheetId] = useState(firstId);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [linkOpen, setLinkOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);
  const [breakOpen, setBreakOpen] = useState(false);
  const [refOpen, setRefOpen] = useState(false);
  const [specialityOpen, setSpecialityOpen] = useState(false);

  const [Id, setId] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [editDateID, setEditDateID] = useState(0);
  const [editDate, setEditDate] = useState("");
  const [editStartTimeID, setEditStartTimeID] = useState(0);
  const [editStartTime, setEditStartTime] = useState("");
  const [editEndTimeID, setEditEndTimeID] = useState(0);
  const [editEndTime, setEditEndTime] = useState("");
  const [editBreakID, setEditBreakID] = useState(0);
  const [editBreak, setEditBreak] = useState("");
  const [editRefID, setEditRefID] = useState("");
  const [editSpecialityID, setEditSpecialityID] = useState("");
  const [editSpeciality, setEditSpeciality] = useState("");
  const [editRef, setEditRef] = useState("");

  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );
  const [trust, setTrust] = useState([]);
  const [hospitalList, setHospitalList] = useState();
  const [wardList, setWardList] = useState([]);
  const [getTrustId, setTrustId] = useState();
  const [getHospitalId, setHospitalId] = useState();
  const [oldSpeciality, setOldSpeciality] = useState();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    hospital_name: true,
    ward_name: true,
    candidate_name: true,
    speciality_name: true,
    reference_id: true,
    date: true,
    start_time: true,
    end_time: true,
    break: true,
    total_hours: true,
    non_awr_umbrella_chargeble: true,
    awr_umbrella_chargeble: true,
    non_awr_paye_chargeble: true,
    rate_calculation_type: true,
    total_holiday_hour: true,
    total_saturday_hour: true,
    total_night_hour: true,
    total_day_hour: true,
    Invoice: true,
    payable: true,
  });
  const [bookingCandidateIds, setBookingCandidateIds] = useState([]);

  const {
    bookingItemInvoice,
    loading,
    shiftStatusSuccess,
    getISFilterDataSuccess,
  } = useSelector((state) => state.booking);
  const [bookingStatus, setBookingStatus] = useState({
    booking_id: "",
    status: "",
  });

  const [isButtonEnabled, setIsButtonEnabled] = useState(true); // Initial column widths
  const [width, setWidth] = useState(300);

  const onResize = (event, { size }) => {
    if (size && size.width) {
      setWidth(size.width);
    }
  };

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -12 : -6);
    return new Date(d.setDate(diff));
  }

  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -12 : 0);
    return new Date(d.setDate(diff));
  }

  const [pdfData, setPdfData] = useState({
    id: [],
  });

  const openTempTimesheet = () => {
    history.push("/admin/timesheet");
  };
  const openInvoiceList = () => {
    history.push("/admin/invoice");
  };

  const getBookingList = (
    pageNo = 1,
    search = "",
    status = "PROVISIONAL",
    specialty = "",
    customer = "",
    site = "",
    ward = "",
    consultants = "",
    candidate = "",
    start_date = firstDayOfWeek,
    end_date = lastDayOfWeek,
    provisional_status = "CONFIRMED",
    type = order,
    column_name = orderBy,
    page_limit = 10
  ) => {
    dispatch(
      getBookingInvoice({
        pageNo,
        search,
        status,
        specialty,
        customer,
        site,
        ward,
        consultants,
        candidate,
        start_date,
        end_date,
        provisional_status,
        type,
        column_name,
        page_limit,
      })
    );
  };
  const [checkedItems, setCheckedItems] = useState({});

  const getFilterDataByModule = () => {
    dispatch(getFilterData("invoice-studio"));
  };

  useEffect(() => {
    if (getISFilterDataSuccess && getISFilterDataSuccess.data) {
      const {
        search,
        specialty,
        customer,
        site,
        ward,
        consultants,
        candidate,
        provisional_status,
        start_date,
        end_date,
      } = getISFilterDataSuccess.data;

      const dataToUpdate = {};

      let iStartDate = searchData?.start_date
        ? moment(searchData?.start_date).format("DD-MM-yyyy")
        : "";
      let sStartDate = start_date
        ? moment(start_date).format("DD-MM-yyyy")
        : "";
      iStartDate = iStartDate !== "" ? iStartDate : sStartDate;
      firstDayOfWeek = iStartDate;
      let iEndDate = searchData?.end_date
        ? moment(searchData?.end_date).format("DD-MM-yyyy")
        : "";
      let sEndDate = end_date ? moment(end_date).format("DD-MM-yyyy") : "";
      iEndDate = iEndDate !== "" ? iEndDate : sEndDate;
      lastDayOfWeek = iEndDate;

      if (specialty !== "") {
        dataToUpdate.specialty = specialty;
      }
      if (customer !== "") {
        dataToUpdate.customer = customer;
        setTrustId(customer);
      }
      if (site !== "") {
        dataToUpdate.site = site;
        gethospital();
        setHospitalId(site);
      }
      if (ward !== "") {
        getWardType(getHospitalId);
        dataToUpdate.ward = ward;
      }
      if (consultants !== "") {
        dataToUpdate.consultants = consultants;
      }
      if (candidate !== "") {
        dataToUpdate.candidate = candidate;
      }
      if (provisional_status) {
        dataToUpdate.provisional_status = provisional_status;
      }
      if (start_date) {
        dataToUpdate.start_date = start_date;
        firstDayOfWeek = start_date;
      }
      if (end_date) {
        dataToUpdate.end_date = end_date;
        lastDayOfWeek = end_date;
      }

      setSearchData((prevData) => ({
        ...prevData,
        ...dataToUpdate,
      }));

      setTimeout(
        getBookingList(
          1,
          searchData?.search ? searchData?.search : search,
          "PROVISIONAL",
          searchData?.specialty ? searchData?.specialty : specialty,
          searchData?.customer ? searchData?.customer : customer,
          searchData?.site ? searchData?.site : site,
          searchData?.ward ? searchData?.ward : ward,
          searchData?.consultants ? searchData?.consultants : consultants,
          searchData?.candidate ? searchData?.candidate : candidate,
          iStartDate,
          iEndDate,
          searchData?.provisional_status
            ? searchData?.provisional_status
            : provisional_status,
          order,
          orderBy
        ),
        1000
      );
    }
  }, [getISFilterDataSuccess]);

  const handleChangeStartTime = (newValue, rowId, key) => {
    const sHours = newValue?.$H; // Accessing the hours property
    const sMinutes = newValue?.$m; // Accessing the minutes property

    const startTime = `${sHours ? sHours : "00"}:${sMinutes ? sMinutes : "00"}`;
    setEditStartTime(startTime);
  };

  const handleChangeEndTime = (newValue, rowId, key) => {
    const sHours = newValue?.$H; // Accessing the hours property
    const sMinutes = newValue?.$m; // Accessing the minutes property

    const endTime = `${sHours ? sHours : "00"}:${sMinutes ? sMinutes : "00"}`;
    setEditEndTime(endTime);
  };
  const handleClickSave = async () => {
    dispatch(createFilter(searchData, "invoice-studio"));
    getFilterDataByModule();
    setPage(1);
    if (getISFilterDataSuccess?.data?.start_date) {
      setSearchData({
        ...searchData,
        start_date: getISFilterDataSuccess?.data?.start_date,
      });
      firstDayOfWeek = getISFilterDataSuccess?.data?.start_date;
    }
    if (getISFilterDataSuccess?.data?.end_date) {
      setSearchData({
        ...searchData,
        end_date: getISFilterDataSuccess?.data?.end_date,
      });
      lastDayOfWeek = getISFilterDataSuccess?.data?.end_date;
    }

    let iStartDate = searchData?.start_date
      ? moment(searchData?.start_date).format("DD-MM-yyyy")
      : "";
    let sStartDate = getISFilterDataSuccess?.data?.start_date
      ? moment(getISFilterDataSuccess?.data?.start_date).format("DD-MM-yyyy")
      : "";
    iStartDate = iStartDate !== "" ? iStartDate : sStartDate;
    firstDayOfWeek = iStartDate;
    let iEndDate = searchData?.end_date
      ? moment(searchData?.end_date).format("DD-MM-yyyy")
      : "";
    let sEndDate = getISFilterDataSuccess?.data?.end_date
      ? moment(getISFilterDataSuccess?.data?.end_date).format("DD-MM-yyyy")
      : "";
    iEndDate = iEndDate !== "" ? iEndDate : sEndDate;
    lastDayOfWeek = iEndDate;
    handleMenuClose(true);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setPdfData(pdfData);
    setTimeout(
      getBookingList(
        value,
        searchData.search,
        "PROVISIONAL",
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? firstDayOfWeek
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? lastDayOfWeek
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        searchData?.provisional_status
      ),
      2000
    );
  };

  const handleSearchChange = (event) => {
    setPage(1);
    searchData.search_timesheet = "";
    const d1 = event.target.value;

    if (d1) {
      setTimeout(
        getBookingList(
          page,
          d1,
          "PROVISIONAL",
          searchData?.specialty,
          searchData?.customer,
          searchData?.site,
          searchData?.ward,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date === null
            ? firstDayOfWeek
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? lastDayOfWeek
            : moment(searchData?.end_date).format("DD-MM-yyyy"),
          searchData?.provisional_status
        ),
        1000
      );
    } else {
      setTimeout(
        getBookingList(
          page,
          "",
          "PROVISIONAL",
          searchData?.specialty,
          searchData?.customer,
          searchData?.site,
          searchData?.ward,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date === null
            ? firstDayOfWeek
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? lastDayOfWeek
            : moment(searchData?.end_date).format("DD-MM-yyyy"),
          searchData?.provisional_status,
          order,
          orderBy
        ),
        1000
      );
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const trustHandleChange = (event) => {
    setTrustId(event.target.value);
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
    gethospital();
  };
  const hospitalHandleChange = (event) => {
    setHospitalId(event.target.value);
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
    getWardType(event.target.value);
  };

  const getTrust = async () => {
    dispatch(
      getBookingTrust((result) => {
        if (result?.status === true) {
          setTrust(result);
        }
      })
    );
  };
  const gethospital = async () => {
    if (getTrustId) {
      dispatch(
        getHospitalList(getTrustId, (result) => {
          if (result?.status === true) {
            setHospitalList(result);
          }
        })
      );
    }
  };
  const getWardType = async (hospitalId) => {
    if (hospitalId && getTrustId) {
      dispatch(
        getWardByHospital(hospitalId, getTrustId, (result) => {
          if (result?.status === true) {
            setWardList(result);
          }
        })
      );
    }
  };

  const getSpecialities = async () => {
    dispatch(
      getBookingSpeciality(0, (result) => {
        if (result?.status === true) {
          setSpeciality(result);
        }
      })
    );
  };

  useEffect(() => {
    if (getTrustId) {
      gethospital();
    }
  }, [getTrustId]);

  useEffect(() => {
    if (getHospitalId) {
      getWardType(getHospitalId);
    }
  }, [getHospitalId]);

  const handleEditDate = (event, editId, sTimeStart, eTimeStart) => {
    if (editStartTimeID > 0 && sTimeStart) {
      setEditStartTimeID(0);
      updateRecord("", editStartTimeID, "edit_start_time");
    }
    if (editEndTimeID > 0 && eTimeStart) {
      setEditEndTimeID(0);
      updateRecord("", editEndTimeID, "edit_end_time");
    }
    setEditDate("");
    setEditDateID(editId);
    setEditStartTimeID(0);
    setEditEndTimeID(0);
    setEditBreakID(0);
    setEditRefID(0);
    setEditSpecialityID(0);
  };

  const handleEditStartTime = (event, editId, sTimeStart, eTimeStart) => {
    if (editEndTimeID > 0 && eTimeStart) {
      setEditEndTimeID(0);
      updateRecord("", editEndTimeID, "edit_end_time");
    }
    setEditStartTime("");
    setEditStartTimeID(editId);
    setEditDateID(0);
    setEditEndTimeID(0);
    setEditBreakID(0);
    setEditRefID(0);
    setEditSpecialityID(0);
  };

  const handleColumnToggle = (column) => {
    console.log("colum", column);
    setVisibleColumns({
      ...visibleColumns,
      [column]: !visibleColumns[column],
    });
  };

  const handleEditEndTime = (event, editId, sTimeStart, eTimeStart) => {
    if (editStartTimeID > 0 && sTimeStart) {
      setEditStartTimeID(0);
      updateRecord("", editStartTimeID, "edit_start_time");
    }

    setEditEndTime("");
    setEditEndTimeID(editId);
    setEditStartTimeID(0);
    setEditDateID(0);
    setEditBreakID(0);
    setEditRefID(0);
    setEditSpecialityID(0);
  };

  const handleEditBreak = (event, editId, sTimeStart, eTimeStart, breakVal) => {
    setEditBreakID(editId);
    setEditBreak(breakVal);
    if (editStartTimeID > 0 && sTimeStart) {
      setEditStartTimeID(0);
      setEditBreakID(0);
      updateRecord("", editStartTimeID, "edit_start_time");
    }
    if (editEndTimeID > 0 && eTimeStart) {
      setEditEndTimeID(0);
      setEditBreakID(0);
      updateRecord("", editEndTimeID, "edit_end_time");
    }
    setEditStartTimeID(0);
    setEditDateID(0);
    setEditEndTimeID(0);
    setEditRefID(0);
    setEditSpecialityID(0);
  };

  const handleEditRefID = (event, editId, sTimeStart, eTimeStart) => {
    if (editStartTimeID > 0 && sTimeStart) {
      setEditStartTimeID(0);
      updateRecord("", editStartTimeID, "edit_start_time");
    }
    if (editEndTimeID > 0 && eTimeStart) {
      setEditEndTimeID(0);
      updateRecord("", editEndTimeID, "edit_end_time");
    }
    setEditRef("");
    setEditRefID(editId);
    setEditStartTimeID(0);
    setEditDateID(0);
    setEditEndTimeID(0);
    setEditBreakID(0);
    setEditSpecialityID(0);
  };

  const handleEditSpecialityID = (event, editId, sTimeStart, eTimeStart) => {
    if (editStartTimeID > 0 && sTimeStart) {
      setEditStartTimeID(0);
      updateRecord("", editStartTimeID, "edit_start_time");
    }
    if (editEndTimeID > 0 && eTimeStart) {
      setEditEndTimeID(0);
      updateRecord("", editEndTimeID, "edit_end_time");
    }
    setEditSpeciality("");
    setEditSpecialityID(editId);
    setEditStartTimeID(0);
    setEditDateID(0);
    setEditEndTimeID(0);
    setEditBreakID(0);
    setEditRefID(0);
  };

  const handleBreakMinute = (event) => {
    event.target.value >= 0
      ? setEditBreak(event.target.value)
      : setEditBreak("");
  };

  const handleRefId = (event) => {
    event.target.value > 0 ? setEditRef(event.target.value) : setEditRef("");
  };

  const handleSearchTimeSheet = (event) => {
    const searchVal = event.target.value;
    searchData.search_timesheet = searchVal;
    if (searchVal) {
      getTempTimesheetDetail(tempSheetId, searchVal);
    } else {
      getTempTimesheetDetail(0, null);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const handleClickSearchTimesheet = (event) => {
    const searchVal = searchData?.search_timesheet;
    handleMenuClose(true);
    if (searchVal) {
      getTempTimesheetDetail(tempSheetId, searchVal);
    } else {
      getTempTimesheetDetail(tempSheetId, "");
    }
  };

  const handleClickSearch = (event, value) => {
    setPage(1);
    searchData.search_timesheet = "";
    handleMenuClose(true);
    setTimeout(
      getBookingList(
        page,
        searchData.search,
        "PROVISIONAL",
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? firstDayOfWeek
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? lastDayOfWeek
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        searchData?.provisional_status,
        order,
        orderBy
      ),
      1000
    );
  };

  const handleReset = () => {
    setPage(1);
    let data = {
      search: "",
      specialty: "",
      candidate: "",
      customer: "",
      site: "",
      ward: "",
      consultants: "",
      start_date: firstDayOfWeek,
      end_date: lastDayOfWeek,
      provisional_status: "CONFIRMED",
      module_name: "invoice-studio",
    };
    setSearchData(data);
    handleMenuClose(true);
    setTimeout(getBookingList(page, "", "PROVISIONAL"), 100);
    dispatch(deleteFilter(data, "invoice-studio"));
    setTrustId("");
    setHospitalId("");
  };

  const getTempTimesheetDetail = async (tempId, sValue) => {
    setTempSheetId(tempId);
    dispatch(
      getTempTimesheetById(tempId, sValue, (result) => {
        if (result) {
          setTempTimesheets(result);
          if (tempId === 0) {
            setTempSheetId(result?.data?.id);
          }
          setFirstId(result?.data?.id);
          setNextId(result?.data?.next_id);
        }
      })
    );
  };

  const deleteTimesheetById = (timesheet_id) => {
    setDisabled(true);
    setId(timesheet_id);
    setDeleteOpen(true);
  };
  const deleteTimesheetClose = () => {
    setDeleteOpen(false);
  };
  const alertResponse = (id) => {
    if (disabled === true) {
      dispatch(deleteTimesheet(id));
    }
    setDisabled(false);
    getTempTimesheetDetail(nextId, "null");
  };

  const linkTimesheetById = (timesheet_id) => {
    if (timesheet_id.length > 0) {
      setDisabled(true);
      setId(timesheet_id);
      setLinkOpen(true);
    } else {
      dispatch(
        notificationFail({
          status: "",
          message: "Please Select Atleast One Booking.",
        })
      );
    }
  };

  const linkTimesheetClose = () => {
    setLinkOpen(false);
  };

  const alertResponseLink = (id) => {
    if (disabled === true) {
      linkTimesheet(id, tempSheetId);
    }
    setDisabled(false);
  };

  const dateTimesheetClose = () => {
    setDateOpen(false);
  };

  const startTimeClose = () => {
    setStartTimeOpen(false);
  };

  const endTimeClose = () => {
    setEndTimeOpen(false);
  };

  const breakClose = () => {
    setBreakOpen(false);
  };

  const refClose = () => {
    setRefOpen(false);
  };

  const specialityClose = () => {
    setSpecialityOpen(false);
  };

  const alertCommonResponse = (id) => {
    if (disabled === true) {
      let startTime;
      let endTime;
      if (editStartTime?.$H || editStartTime?.$m) {
        startTime = `${editStartTime?.$H}:${editStartTime?.$m}`;
      }
      if (editEndTime?.$H || editEndTime?.$m) {
        endTime = `${editEndTime?.$H}:${editEndTime?.$m}`;
      }
      updateInvoice(
        id,
        editDate,
        startTime,
        endTime,
        editBreak,
        editRef,
        editSpeciality
      );
    }
    setDisabled(false);
  };

  useEffect(() => {
    getTempTimesheetDetail(tempSheetId, "null");
  }, []);

  useEffect(() => {
    if (
      (loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION"
    ) {
      getSpecialities();
      // getBookingList()
      getTrust();
      gethospital();
      getWardType(getHospitalId);
      getFilterDataByModule();
    }
  }, []);

  const resetBooking = () => {
    setBookingCandidateIds([]);
    setBookingCandidate([]);
    setCustomerIds([]);
  };
  useEffect(() => {
    setBookingCandidate(bookingCandidateIds);
  }, [bookingCandidateIds]);
  const handleCheckboxClick = (
    event,
    id,
    booking_reference,
    candidate_id,
    customer_id
  ) => {
    const ids = [...bookingId];
    const refs = [...bookingRef];
    // setBookingId(id);
    const isChecked = event.target.checked;

    // Update the checkedItems state with the new checkbox state
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));

    if (event.target.checked) {
      customerIds.push(customer_id);
      setCustomerIds(customerIds);
      ids.push(id);
      refs.push(booking_reference);
      setBookingCandidateIds([
        ...bookingCandidateIds,
        {
          booking_id: id,
          candidate_id: candidate_id,
          booking_ref: booking_reference,
        },
      ]);
      setBookingId([...ids]);
      setBookingRef([...refs]);
    } else {
      if (customerIds.filter((item) => item === customer_id)) {
        let customerIndex = customerIds.indexOf(customer_id);
        customerIds.splice(customerIndex, 1);
        setCustomerIds(customerIds);
      } else {
        setCustomerIds(customerIds.filter((item) => item != customer_id));
      }
      setBookingId(ids.filter((item) => item != id));
      setBookingRef(refs.filter((item) => item != booking_reference));
      setBookingCandidateIds(
        bookingCandidateIds.filter((item) => item.booking_id !== id)
      );
    }
    if (customerIds.length === 1) {
      setIsButtonEnabled(true);
    } else if (customerIds.length > 1) {
      const checkAllSame = (array) => {
        if (array.length === 0) {
          return false;
        }
        const firstElement = array[0];
        for (let i = 1; i < array.length; i++) {
          if (array[i] !== firstElement) {
            return false;
          }
        }
        return true;
      };
      if (checkAllSame(customerIds)) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
        dispatch(
          notificationFail({
            status: "",
            message: "Please Select same customer booking for add expence",
          })
        );
      }
    }
  };
  const resetCheckboxes = async () => {
    try {
      setCheckedItems({});
    } catch (error) {
      console.error("API call failed:", error);
    }
  };
  useEffect(() => {
    if (bookingStatus.booking_id !== "") {
      dispatch(changeShiftStatus(bookingStatus));
      setBookingNotify(true);
      setTimeout(() => {
        getBookingList();
      }, 4000);
    }
  }, [bookingStatus]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    if (
      searchData.search === "" &&
      searchData.specialty === "" &&
      searchData.customer === "" &&
      searchData.site === "" &&
      searchData.ward === "" &&
      searchData.consultants === "" &&
      searchData.candidate === "" &&
      searchData.start_date === "" &&
      searchData.end_date === "" &&
      searchData.provisional_status === ""
    ) {
      getFilterDataByModule();
    }
    setAnchorEl(event.currentTarget);
    firstDayOfWeek = getISFilterDataSuccess?.data?.start_date;
    lastDayOfWeek = getISFilterDataSuccess?.data?.end_date;
    gethospital();
    getWardType(getHospitalId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateRecord = (e, bId, fieldName, oSpeciality = 0) => {
    if (fieldName === "edit_speciality_id") {
      setOldSpeciality(oSpeciality);
      setEditSpeciality(e.target.value);
      setSpecialityOpen(true);
    }
    if (fieldName === "edit_ref_id") {
      setEditRef(e.target.value);
      setRefOpen(true);
    }
    if (fieldName === "edit_date") {
      setEditDate(e.target.value);
      setDateOpen(true);
    }
    if (fieldName === "edit_start_time") {
      setStartTimeOpen(true);
    }

    if (fieldName === "edit_end_time") {
      setEndTimeOpen(true);
    }
    if (fieldName === "edit_break") {
      setBreakOpen(true);
    }

    setDisabled(true);
    setId(bId);
  };
  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const updateInvoice = (
    bookingId,
    editDate = "",
    editStartTime = "",
    editEndTime = "",
    editBreak = "",
    editRef = ""
  ) => {
    if (bookingId > 0) {
      setEditDate("");
      setEditStartTime("");
      setEditEndTime("");
      setEditBreak("");
      setEditRef("");
      setEditSpeciality("");

      setEditDateID("");
      setEditStartTimeID("");
      setEditEndTimeID("");
      setEditBreakID("");
      setEditRefID("");
      setEditSpecialityID("");

      setLoadings(true);
      let formData = new FormData();
      formData.append("booking_id", bookingId);
      if (editDate !== "") {
        formData.append("booking_date", editDate);
      }
      if (editStartTime !== "") {
        formData.append("start_time", editStartTime);
      }
      if (editEndTime !== "") {
        formData.append("end_time", editEndTime);
      }
      if (editBreak !== 0 && editBreak !== "") {
        formData.append("break", editBreak);
      } else {
        if (editBreakID > 0) {
          formData.append("break", 0);
        }
      }

      if (editRef !== "") {
        formData.append("booking_reference", editRef);
      }

      if (editSpeciality !== "") {
        formData.append("old_speciality_id", oldSpeciality);
        formData.append("speciality_id", editSpeciality);
      }

      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      axios
        .post(
          `${apiConfigs.API_URL}api/organization/update-invoice`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${loggedInUser}`,
            },
          }
        )
        .then((response) => {
          const dataItem = response.data;
          if (dataItem && dataItem.status === true) {
            setLoadings(false);
            setTimeout(() => {
              getBookingList(
                page,
                searchData.search,
                "PROVISIONAL",
                searchData?.specialty,
                searchData?.customer,
                searchData?.site,
                searchData?.ward,
                searchData?.consultants,
                searchData?.candidate,
                searchData?.start_date === null
                  ? firstDayOfWeek
                  : moment(searchData?.start_date).format("DD-MM-yyyy"),
                searchData?.end_date === null
                  ? lastDayOfWeek
                  : moment(searchData?.end_date).format("DD-MM-yyyy"),
                searchData?.provisional_status,
                order,
                orderBy
              );
            }, 2000);

            dispatch(notificationSuccess(dataItem?.message));
          } else {
            setTimesheetError(dataItem);
            dispatch(notificationFail(dataItem?.message));
            setLoadings(false);
          }
        })
        .catch((error) => {
          setTimesheetError("Sorry record updated failed");
          setLoadings(false);
        });
    }
  };

  const linkTimesheet = (bookingId, tTimesheetId) => {
    setLoadings(true);
    let formData = new FormData();
    formData.append("booking_id", bookingId);
    formData.append("temp_timesheet_id", tTimesheetId);
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(`${apiConfigs.API_URL}api/organization/link-timesheet`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        resetCheckboxes();
        setBookingCandidateIds([]);
        setBookingCandidate([]);
        setCustomerIds([]);
        const dataItem = response.data;
        if (dataItem && dataItem.status === true) {
          setTimesheetError(dataItem);
          setLoadings(false);
          getTempTimesheetDetail(nextId, "null");
          setTimeout(() => {
            getBookingList(
              page,
              searchData.search,
              "PROVISIONAL",
              searchData?.specialty,
              searchData?.customer,
              searchData?.site,
              searchData?.ward,
              searchData?.consultants,
              searchData?.candidate,
              searchData?.start_date === null
                ? firstDayOfWeek
                : moment(searchData?.start_date).format("DD-MM-yyyy"),
              searchData?.end_date === null
                ? lastDayOfWeek
                : moment(searchData?.end_date).format("DD-MM-yyyy"),
              searchData?.provisional_status,
              order,
              orderBy
            );
          }, 1000);
          resetCheckboxes();
          setBookingId([]);
          setBookingRef([]);
          dispatch(notificationSuccess(dataItem?.message));
        } else {
          setTimesheetError(dataItem);
          dispatch(notificationFail(dataItem?.message));
          setLoadings(false);
        }
      })
      .catch((error) => {
        setTimesheetError("Sorry timesheet upload failed");
        setLoadings(false);
      });
  };

  const handleUploadTimeSheet = (e) => {
    setLoadings(true);

    const pic = e.target.files;
    let formData = new FormData();
    for (const file of pic) {
      formData.append("timesheets[]", file, file.name);
    }

    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(
        `${apiConfigs.API_URL}api/organization/upload-multiple-timesheet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem && dataItem.status === true) {
          setTimesheetError(dataItem);
          setLoadings(false);
          getTempTimesheetDetail(0, "null");
          getBookingList();
        } else {
          setTimesheetError(dataItem);
          setLoadings(false);
        }
      })
      .catch((error) => {
        setTimesheetError("Sorry timesheet upload failed");
        setLoadings(false);
      });
  };

  const handleClickOpen = (id) => {
    if (bookingId.length > 0) {
      setOpen(true);
    } else {
      dispatch(
        notificationFail({
          status: "",
          message: "Please Select Atleast One Booking.",
        })
      );
    }
  };

  const handleClose = (action) => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      (loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION"
    ) {
      let iStartDate = searchData?.start_date
        ? moment(searchData?.start_date).format("DD-MM-yyyy")
        : firstDayOfWeek;
      iStartDate = getISFilterDataSuccess?.data?.start_date
        ? moment(getISFilterDataSuccess?.data?.start_date).format("DD-MM-yyyy")
        : iStartDate;

      let iEndDate = searchData?.end_date
        ? moment(searchData?.end_date).format("DD-MM-yyyy")
        : lastDayOfWeek;
      iEndDate = getISFilterDataSuccess?.data?.end_date
        ? moment(getISFilterDataSuccess?.data?.end_date).format("DD-MM-yyyy")
        : iEndDate;
      getBookingList(
        page,
        searchData?.search
          ? searchData?.search
          : getISFilterDataSuccess?.data?.search,
        "PROVISIONAL",
        searchData?.specialty
          ? searchData?.specialty
          : getISFilterDataSuccess?.data?.specialty,
        searchData?.customer
          ? searchData?.customer
          : getISFilterDataSuccess?.data?.customer,
        searchData?.site
          ? searchData?.site
          : getISFilterDataSuccess?.data?.site,
        searchData?.ward
          ? searchData?.ward
          : getISFilterDataSuccess?.data?.ward,
        searchData?.consultants
          ? searchData?.consultants
          : getISFilterDataSuccess?.data?.consultants,
        searchData?.candidate
          ? searchData?.candidate
          : getISFilterDataSuccess?.data?.candidate,

        iStartDate,
        iEndDate,
        searchData?.provisional_status,
        order,
        orderBy
      );
    }
  }, [orderBy, order]);

  const handleEditStartTimeFunc = (e) => {
    if (e) {
      setEditStartTime(e);
    }
  };
  const handleEditEndTimeFunc = (e) => {
    if (e) {
      setEditEndTime(e);
    }
  };

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {loadings ? (
        <Backdrop className={classes.backdrop} open={loadings}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {bookingNotify && shiftStatusSuccess?.message && (
        <Notification data={shiftStatusSuccess?.message} status="success" />
      )}

      {timesheetMsgError && timesheetMsgError?.message && (
        <Notification
          data={timesheetMsgError?.message}
          status={timesheetMsgError?.status === true ? "success" : "error"}
        />
      )}
      {(loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION" ? (
        <>
          <Paper className={`${classes.root} mb-1`}>
            <Grid container spacing={0} md={12} xs={12} sm={12} lg={12}>
              <Grid xs={6} sm={6} md={6} lg={6}>
                <Typography variant="h5"></Typography>
              </Grid>

              <Grid item md={6} xs={6} sm={6} lg={6}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className={classes.btnContainer}
                >
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    color="secondary"
                    onClick={handleClickOpen}
                    justifyContent="space-between"
                    className={classes.buttonAlign}
                    disabled={!isButtonEnabled}
                  >
                    Add Expense
                  </Button>
                  <Button
                    title="Upload Timesheet"
                    variant="contained"
                    color="secondary"
                    style={{ textTransform: "capitalize" }}
                    justifyContent="space-between"
                    className={classes.buttonAlign}
                  >
                    <input
                      key={"s"}
                      accept="image/*,.pdf"
                      type="file"
                      multiple
                      name="timesheet"
                      onChange={(event) => handleUploadTimeSheet(event)}
                      style={{
                        opacity: "0",
                        position: "absolute",
                        width: "100px",
                        height: "35px",
                        cursor: "pointer",
                      }}
                    />
                    <label class="mr-1">Upload Timesheets</label>
                  </Button>
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    color="secondary"
                    onClick={openTempTimesheet}
                    justifyContent="space-between"
                    className={classes.buttonAlign}
                  >
                    Timesheets
                  </Button>
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    color="secondary"
                    onClick={openInvoiceList}
                    justifyContent="space-between"
                    className={classes.buttonAlign}
                  >
                    Invoice List
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            spacing={0}
            style={{ overflowX: "auto", flexWrap: "unset" }}
          >
            <Resizable
              width={width}
              height={400} // Set your preferred height
              onResize={onResize}
              draggableOpts={{ enableUserSelectHack: false }}
              className={classes.resizableBox} // Your class for styling
              xs={12}
              sm={12}
              md={5}
              lg={5}
              sx={{ flex: "0 0 auto", maxWidth: "30%" }}
            >
              <Grid
                className={`${classes.gridWrapper} ${classes.customBackground} ${classes.gridContent}`}
              >
                <Box
                  marginLeft="10px"
                  marginRight="10px"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Box marginLeft="10px">
                    <h3>
                      <b>Timesheets</b>
                    </h3>
                    <br />
                  </Box>
                  <Box marginLeft="10px" display="flex" alignItems="center">
                    <>
                      <SearchIcon
                        className={classes.searchIcondet}
                        onClick={(e) => handleClickSearchTimesheet(e)}
                      />
                      <div className={classes.search}>
                        <InputBase
                          name="search_timesheet"
                          placeholder="Search…"
                          onChange={(e) => handleSearchTimeSheet(e)}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                        />
                      </div>
                    </>
                    {tempTimesheets?.data !== undefined &&
                      tempTimesheets?.data &&
                      tempTimesheets?.data?.prev_id > 0 && (
                        <>
                          <Box className={classes.buttonWrapper}>
                            <IconButton
                              onClick={() =>
                                getTempTimesheetDetail(
                                  tempTimesheets?.data?.prev_id,
                                  "null"
                                )
                              }
                              style={{ padding: 6 }}
                            >
                              <ChevronLeftIcon color="secondary" />
                            </IconButton>
                          </Box>
                        </>
                      )}
                    {tempTimesheets?.data !== undefined &&
                      tempTimesheets?.data &&
                      tempTimesheets?.data?.next_id > 0 && (
                        <>
                          <Box className={classes.buttonWrapper}>
                            <IconButton
                              onClick={() =>
                                getTempTimesheetDetail(
                                  tempTimesheets?.data?.next_id,
                                  "null"
                                )
                              }
                              style={{ padding: 6 }}
                            >
                              <ChevronRightIcon color="secondary" />
                            </IconButton>
                          </Box>
                        </>
                      )}

                    {tempTimesheets?.data !== undefined &&
                      tempTimesheets?.data && (
                        <>
                          <Box className={classes.buttonWrapper}>
                            <IconButton
                              onClick={() =>
                                deleteTimesheetById(tempTimesheets?.data?.id)
                              }
                              style={{ padding: 6 }}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </Box>
                        </>
                      )}
                    {tempTimesheets?.data !== undefined &&
                      tempTimesheets?.data && (
                        <>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => linkTimesheetById(bookingId)}
                            className={classes.linkWrapper}
                            disabled={!isButtonEnabled}
                            sx={{
                              height: "20px",
                              alignItems: "center",
                              width: "20px",
                            }}
                          >
                            Link
                          </Button>
                        </>
                      )}
                  </Box>

                  {tempTimesheets?.data !== null &&
                  tempTimesheets?.data !== undefined &&
                  tempTimesheets?.data?.timesheet_name ? (
                    <>
                      <Box
                        mt={0}
                        display="flex"
                        alignItems="center"
                        marginBottom={3}
                        marginTop={3}
                        marginLeft="10px"
                      >
                        {tempTimesheets?.data?.timesheet_name}
                      </Box>
                      <object
                        data={
                          apiConfigs.API_URL +
                          "uploads/temp_timesheet/" +
                          tempTimesheets?.data?.timesheet_name
                        }
                        width="100%"
                        height="100%"
                        style={{ minHeight: "720px" }}
                      ></object>
                    </>
                  ) : (
                    <object></object>
                  )}
                </Box>
              </Grid>
            </Resizable>

            {/* <Resizable
              width={width}
              height={400} // Set your preferred height
              // onResize={onResize}
              // draggableOpts={{ enableUserSelectHack: false }}
              // className={classes.resizableBox} // Your class for styling
              xs={12} sm={12} md={5} lg={5}
              sx={{ flex: '0 0 auto',  maxWidth:'70%' }}
            > */}
            <Grid
              className={`${classes.gridWrapper} ${classes.customBackground} ${classes.gridContent}`}
            >
              <Box marginLeft="10px" marginRight="10px" width="100%">
                <Box marginLeft="10px">
                  <h3>
                    <b>Bookings</b>
                  </h3>
                  <br />
                </Box>
                <Box
                  mb={0}
                  marginLeft="10px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <>
                      <SearchIcon
                        className={classes.searchIcondet}
                        onClick={handleClickSearch}
                      />
                      <div className={classes.search}>
                        <InputBase
                          name="search"
                          placeholder="Search…"
                          onChange={handleSearchChange}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                        />
                      </div>
                    </>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Tooltip title="Filters">
                      <Button onClick={handleMenu}>
                        <FilterListIcon />
                      </Button>
                    </Tooltip>
                    <EnhancedTableToolbar
                      numSelected={selected.length}
                      visibleColumns={visibleColumns}
                      handleColumnToggle={handleColumnToggle}
                      headCells={headCells[0]}
                    />

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      open={openMenu}
                      onClose={handleMenuClose}
                      className={classes.filterBookingSearch}
                    >
                      <div className={classes.filterBox}>
                        <Box className={classes.radioGroup}>
                          <Box display="flex" alignItems="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              flexGrow={1}
                            >
                              <FormControl
                                className={classes.formControl}
                                variant="outlined"
                              >
                                <InputLabel>Customer</InputLabel>
                                <Select
                                  label="Customer"
                                  name="customer"
                                  onChange={trustHandleChange}
                                  value={
                                    searchData?.customer
                                      ? searchData?.customer
                                      : getTrustId
                                  }
                                >
                                  <MenuItem value="">Select Customer</MenuItem>
                                  {trust?.data !== undefined &&
                                    trust?.data &&
                                    trust?.data.map((trustList, index) => {
                                      return (
                                        <MenuItem
                                          value={trustList.id}
                                          key={index + 11111}
                                        >
                                          {trustList.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>

                              <FormControl
                                className={classes.formControl}
                                variant="outlined"
                              >
                                <InputLabel>Site</InputLabel>
                                <Select
                                  label="Site"
                                  name="site"
                                  onChange={hospitalHandleChange}
                                  value={
                                    searchData?.site
                                      ? searchData?.site
                                      : getHospitalId
                                  }
                                >
                                  <MenuItem value="">Select Site</MenuItem>
                                  {hospitalList?.data !== undefined &&
                                    hospitalList?.data &&
                                    hospitalList?.data.map((List, index) => {
                                      return (
                                        <MenuItem
                                          value={List.id}
                                          key={index + 1}
                                        >
                                          {List.hospital_name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>

                              <Box className={classes.formControl}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={customEnLocale}
                                  localeText={{
                                    start: "Check-in",
                                    end: "Check-out",
                                  }}
                                >
                                  <DatePicker
                                    label="Start Date"
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) =>
                                      setSearchData({
                                        ...searchData,
                                        start_date: newValue,
                                      })
                                    }
                                    firstDayOfWeek={1}
                                    value={
                                      searchData?.start_date
                                        ? searchData?.start_date
                                        : firstDayOfWeek
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box className={classes.formControl}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={customEnLocale}
                                  localeText={{
                                    start: "Check-in",
                                    end: "Check-out",
                                  }}
                                >
                                  <DatePicker
                                    label="End Date"
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) =>
                                      setSearchData({
                                        ...searchData,
                                        end_date: newValue,
                                      })
                                    }
                                    lastDayOfWeek={1}
                                    value={
                                      searchData?.end_date
                                        ? searchData?.end_date
                                        : lastDayOfWeek
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Box>
                          </Box>

                          <Box mt={2} mb={1} display="flex" alignItems="end">
                            <Box
                              display="flex"
                              alignItems="end"
                              justifyContent="space-between"
                              flexGrow={1}
                            >
                              <FormControl
                                className={classes.formControl}
                                variant="outlined"
                              >
                                <InputLabel>Ward</InputLabel>
                                <Select
                                  label="Ward"
                                  name="ward"
                                  onChange={(e) =>
                                    setSearchData({
                                      ...searchData,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  value={searchData?.ward}
                                >
                                  <MenuItem value="">Select Ward</MenuItem>
                                  {wardList?.data !== undefined &&
                                    wardList?.data &&
                                    wardList?.data.map((list, index) => {
                                      return (
                                        <MenuItem
                                          value={list.id}
                                          key={index + 11}
                                        >
                                          {list.ward_name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                              <Box
                                mt={2}
                                mb={1}
                                display="flex"
                                alignItems="end"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  flexGrow={1}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                    className={classes.formControl}
                                  >
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        marginRight: "16px",
                                        marginLeft: "16px",
                                      }}
                                      className={classes.searchIcondet}
                                      onClick={handleClickSearch}
                                    >
                                      Search
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleReset}
                                    >
                                      Reset
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        marginRight: "16px",
                                        marginLeft: "16px",
                                      }}
                                      className={classes.searchIcondet}
                                      onClick={handleClickSave}
                                    >
                                      Save
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </Menu>
                  </Box>
                </Box>

                <TableContainer>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(event, property) => {
                        handleRequestSort(
                          property,
                          setOrder,
                          setOrderBy,
                          orderBy,
                          order
                        );
                      }}
                      rowCount={bookingItemInvoice?.data?.data?.length}
                      headCells={headCells[0]}
                      activeIndex={0}
                      pdfDataCount={pdfData.id.length}
                      visibleColumns={visibleColumns}
                    />

                    <TableBody>
                      {bookingItemInvoice?.data?.data &&
                        bookingItemInvoice?.data?.data.map((row, index) => {
                          const dateFormate = row.date
                            .toString()
                            .split("-")
                            .reverse()
                            .join("-");

                          return (
                            <TableRow key={index + 666}>
                              <TableCell scope="row">
                                <RadioGroup
                                  name="payment_scheme_type"
                                  value={bookingId}
                                  onChange={(event) =>
                                    handleCheckboxClick(
                                      event,
                                      row.id,
                                      row.booking_reference,
                                      row.candidate_id,
                                      row.trust_id
                                    )
                                  }
                                  className={classes.radioGroup}
                                >
                                  <FormControlLabel
                                    value={row.id}
                                    control={
                                      <Checkbox
                                        checked={checkedItems[row.id] || false}
                                        onChange={(event) =>
                                          handleCheckboxClick(
                                            event,
                                            row.id,
                                            row.booking_reference,
                                            row.candidate_id,
                                            row.trust_id
                                          )
                                        }
                                      />
                                    }
                                  />
                                </RadioGroup>
                              </TableCell>
                              {visibleColumns.name && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  {row.code}
                                </TableCell>
                              )}
                              {visibleColumns.hospital_name && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  {row.hospital_name}
                                </TableCell>
                              )}
                              {visibleColumns.ward_name && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  {row.ward_name}
                                </TableCell>
                              )}
                              {visibleColumns.candidate_name && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  {row.candidate_name}
                                </TableCell>
                              )}
                              {visibleColumns.speciality_name &&
                                (editSpecialityID !== row.id ? (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "wrap" }}
                                    onClick={(e) =>
                                      handleEditSpecialityID(
                                        e,
                                        row.id,
                                        row?.start_time,
                                        row?.end_time
                                      )
                                    }
                                  >
                                    {row.speciality_name}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "wrap" }}
                                  >
                                    <Select
                                      value={row?.speciality_id}
                                      onChange={(e) =>
                                        updateRecord(
                                          e,
                                          row.id,
                                          "edit_speciality_id",
                                          row?.speciality_id
                                        )
                                      }
                                      name="speciality"
                                    >
                                      {speciality?.data !== undefined &&
                                        speciality?.data &&
                                        speciality?.data.map((items, index) => (
                                          <MenuItem
                                            value={items.id}
                                            key={index}
                                          >
                                            {items.speciality_name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </TableCell>
                                ))}
                              {visibleColumns.reference_id &&
                                (editRefID !== row.id ? (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "wrap" }}
                                    onClick={(e) =>
                                      handleEditRefID(
                                        e,
                                        row.id,
                                        row?.start_time,
                                        row?.end_time
                                      )
                                    }
                                  >
                                    {row.booking_reference}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <TextField
                                      id="booking_reference"
                                      type="text"
                                      name="booking_reference"
                                      className={classes.textField}
                                      variant="outlined"
                                      value={
                                        editRef !== ""
                                          ? editRef
                                          : row.booking_reference
                                      }
                                      onChange={(e) => handleRefId(e)}
                                      fullWidth
                                      required
                                      inputProps={{
                                        min: 60,
                                      }}
                                    />
                                  </TableCell>
                                ))}
                              {visibleColumns.date &&
                                (editDateID !== row.id ? (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={(e) =>
                                      handleEditDate(
                                        e,
                                        row.id,
                                        row?.start_time,
                                        row?.end_time
                                      )
                                    }
                                  >
                                    {dateFormate}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <TextField
                                      id="date"
                                      type="date"
                                      name="date"
                                      value={editDate ? editDate : row.date}
                                      className={classes.textField}
                                      variant="outlined"
                                      onChange={(e) =>
                                        updateRecord(e, row.id, "edit_date")
                                      }
                                      fullWidth
                                    />
                                  </TableCell>
                                ))}
                              {visibleColumns.start_time &&
                                (editStartTimeID !== row.id ? (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={(e) =>
                                      handleEditStartTime(
                                        e,
                                        row.id,
                                        row?.start_time,
                                        row?.end_time
                                      )
                                    }
                                  >
                                    {row.start_time}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <TimePicker
                                          label="Time"
                                          className="customClock custom-clock-input"
                                          value={
                                            editStartTime
                                              ? editStartTime
                                              : dayjs(row.start_time, "hh:mm")
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              onBlur={(e) => {
                                                if (
                                                  e.target.value !== null &&
                                                  e.target.value !==
                                                    undefined &&
                                                  e.target.value !== ""
                                                ) {
                                                  updateRecord(
                                                    e,
                                                    row.id,
                                                    "edit_start_time"
                                                  );
                                                } else {
                                                  setEditStartTime(
                                                    row.start_time
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                          onClose={() => {
                                            updateRecord(
                                              "",
                                              row.id,
                                              "edit_start_time"
                                            );
                                          }}
                                          onChange={(e) =>
                                            handleEditStartTimeFunc(e)
                                          }
                                          ampm={false}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </TableCell>
                                ))}

                              {visibleColumns.end_time &&
                                (editEndTimeID !== row.id ? (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={(e) =>
                                      handleEditEndTime(
                                        e,
                                        row.id,
                                        row?.start_time,
                                        row?.end_time
                                      )
                                    }
                                  >
                                    {row.end_time}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <TimePicker
                                          label="Time"
                                          className="customClock custom-clock-input"
                                          value={
                                            editEndTime
                                              ? editEndTime
                                              : dayjs(row.end_time, "hh:mm")
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              onBlur={(e) => {
                                                if (
                                                  e.target.value !== null &&
                                                  e.target.value !==
                                                    undefined &&
                                                  e.target.value !== ""
                                                ) {
                                                  updateRecord(
                                                    e,
                                                    row.id,
                                                    "edit_end_time"
                                                  );
                                                } else {
                                                  setEditEndTime(row.end_time);
                                                }
                                              }}
                                            />
                                          )}
                                          onClose={() => {
                                            updateRecord(
                                              "",
                                              row.id,
                                              "edit_end_time"
                                            );
                                          }}
                                          onChange={(e) =>
                                            handleEditEndTimeFunc(e)
                                          }
                                          ampm={false}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </TableCell>
                                ))}
                              {visibleColumns.break &&
                                editBreakID !== row.id && (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={(e) =>
                                      handleEditBreak(
                                        e,
                                        row.id,
                                        row?.start_time,
                                        row?.end_time,
                                        row.break
                                      )
                                    }
                                  >
                                    {row.break}
                                  </TableCell>
                                )}
                              {/* {visibleColumns.total_hours && (
                                <TableCell align="left">
                                  {row.total_hours}
                                </TableCell>
                              )} */}

                              {editBreakID === row.id && editBreak !== "" ? (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                  onChange={(e) =>
                                    updateRecord(e, row.id, "edit_break")
                                  }
                                >
                                  <TextField
                                    autoFocus
                                    id="break"
                                    type="number"
                                    name="break"
                                    value={
                                      editBreak >= 0 ? editBreak : row.break
                                    }
                                    variant="outlined"
                                    onChange={(e) => handleBreakMinute(e)}
                                    fullWidth
                                    maxLength={3}
                                  />
                                </TableCell>
                              ) : (
                                editBreakID === row.id && (
                                  <TableCell
                                    align="left"
                                    style={{ whiteSpace: "nowrap" }}
                                    onChange={(e) =>
                                      updateRecord(e, row.id, "edit_break")
                                    }
                                  >
                                    <TextField
                                      id="break"
                                      type="number"
                                      name="break"
                                      autoFocus
                                      value={""}
                                      variant="outlined"
                                      onChange={(e) => handleBreakMinute(e)}
                                      fullWidth
                                      maxLength={3}
                                    />
                                  </TableCell>
                                )
                              )}

                              {visibleColumns.non_awr_umbrella_chargeble && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.non_awr_umbrella_chargeble}
                                </TableCell>
                              )}
                              {visibleColumns.awr_umbrella_chargeble && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.awr_umbrella_chargeble}
                                </TableCell>
                              )}
                              {visibleColumns.non_awr_paye_chargeble && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.non_awr_paye_chargeble}
                                </TableCell>
                              )}
                              {visibleColumns.rate_calculation_type && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                 {row.rate_calculation_type === 'awr_paye'
                                    ? 'Awr Payee'
                                    : row.rate_calculation_type === 'non_awr_paye'
                                    ? 'Non Awr Paye'
                                    : row.rate_calculation_type === 'awr_umbrella'
                                    ? 'Awr Umbrella'
                                    : 'Non Awr Umbrella'}
                                </TableCell>
                              )}
                              {visibleColumns.payable && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.candidate_payable}
                                </TableCell>
                              )}
                              {visibleColumns.total_holiday_hour && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.total_holiday_hour}
                                </TableCell>
                              )}
                              {visibleColumns.total_saturday_hour && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.total_saturday_hour}
                                </TableCell>
                              )}
                              {visibleColumns.total_night_hour && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.total_night_hour}
                                </TableCell>
                              )}
                              {visibleColumns.total_day_hour && (
                                <TableCell
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {row.total_day_hour}
                                </TableCell>
                              )}
                              {visibleColumns.Invoice && (
                                <TableCell className="ml-0" align="right">
                                  <Link
                                    to={`bookings/${row.id}/update`}
                                    className="btn btn-secondary"
                                  >
                                    Edit
                                  </Link>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      {!bookingItemInvoice?.data && (
                        <TableRow>
                          <TableCell scope="row" colSpan="15">
                            <div className="" align="center">
                              Sorry, booking not available!
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="mt-5" display="flex" justifyContent="flex-end">
                  <Pagination
                    onChange={handleChangePage}
                    page={page}
                    count={bookingItemInvoice?.data?.last_page}
                    showFirstButton
                    showLastButton
                  />
                </Box>
              </Box>
            </Grid>
            {/* </Resizable> */}
          </Grid>

          <AlertDialog
            id={Id}
            response={alertResponse}
            open={deleteOpen}
            close={deleteTimesheetClose}
            title="Delete Timesheet"
            description="Are you sure you want to delete timesheet?"
            buttonName="Delete"
          />

          <AlertDialog
            id={Id}
            response={alertResponseLink}
            open={linkOpen}
            close={linkTimesheetClose}
            title="Link Timesheet"
            description="Are you sure you want to link this timesheet?"
            buttonName="Link"
          />

          <AlertDialog
            id={Id}
            response={alertCommonResponse}
            open={dateOpen}
            close={dateTimesheetClose}
            title="Invoice Date"
            description="Are you sure you want to change this date?"
            buttonName="Change"
          />

          <AlertDialog
            id={Id}
            response={alertCommonResponse}
            open={startTimeOpen}
            close={startTimeClose}
            title="Invoice Start Time"
            description="Are you sure you want to change this start time?"
            buttonName="Change"
          />

          <AlertDialog
            id={Id}
            response={alertCommonResponse}
            open={endTimeOpen}
            close={endTimeClose}
            title="Invoice End Time"
            description="Are you sure you want to change this end time?"
            buttonName="Change"
          />

          <AlertDialog
            id={Id}
            response={alertCommonResponse}
            open={breakOpen}
            close={breakClose}
            title="Invoice Break"
            description="Are you sure you want to change this break?"
            buttonName="Change"
          />

          <AlertDialog
            id={Id}
            response={alertCommonResponse}
            open={refOpen}
            close={refClose}
            title="Reference ID"
            description="Are you sure you want to change this Reference ID?"
            buttonName="Change"
          />

          <AlertDialog
            id={Id}
            response={alertCommonResponse}
            open={specialityOpen}
            close={specialityClose}
            title="Speciality"
            description="Are you sure you want to change this Speciality?"
            buttonName="Change"
          />

          {/* </Paper> */}
        </>
      ) : (
        <div className="" align="center">
          <b>Sorry,You have no access!</b>
        </div>
      )}
      <AddExpense
        open={open}
        handleClose={handleClose}
        bookingRef={bookingRef}
        bookingCandidate={bookingCandidate}
        type={"invoice-studio"}
        tempId={tempSheetId}
        resetCheckboxes={resetCheckboxes || null}
        setLoadings={setLoadings}
        getTempTimesheetDetail={getTempTimesheetDetail || null}
        nextId={nextId}
        getBookingList={getBookingList}
        firstDayOfWeek={firstDayOfWeek}
        lastDayOfWeek={lastDayOfWeek}
        searchData={searchData}
        order={order}
        orderBy={orderBy}
        setBookingId={setBookingId}
        setBookingRef={setBookingRef}
        bookingRefrence={true}
        resetBooking={() => resetBooking()}
      />
    </>
  );
};

export default InvoiceStudio;
